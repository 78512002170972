$(document).ready(function() {

  $("form").validate({
    ignore: ':hidden:not(:checkbox)',
    errorElement: 'label',
    errorClass: 'is-invalid',
    validClass: 'is-valid',
  });

  $('#data-table').DataTable({
    "language": {
      "emptyTable": "No tickets have been submitted."
    },
    "order": [
      [0, "desc"]
    ]
  });
  autosize($('textarea#support_description'));

  $("form[id='cretaeSupport']").on('submit', function() {
    var input = $("#cretaeSupport :input");
    if (input.hasClass("is-invalid")) {} else {
      $('#overlay').fadeIn();
      $("#cretaeSupport :input").prop("disabled", false);
    }
  });

  $("#support_client_id").change(function() {
    var client_id = $(this).val();
    var client_name = $("#support_client_id option:selected").text();
    if (client_id == 39 && client_name == "IEG") {
      $('#support_category_id').append(new Option('Emergency web update', 7));
      $('#support_category_id').val(7);
      $("#support_severity_level_id").val(1).prop('disabled', 'disabled');
      $("#support_issue_id").val(3).prop('disabled', true);
      $("#support_affected_user_id").val(1).prop('disabled', true);
    } else {
      $("#support_category_id option[value='7']").remove();
      $('#support_category_id').val('');
      $("#support_category_id").prop('disabled', false);
      $('#support_severity_level_id').val('');
      $("#support_severity_level_id").prop('disabled', false);
    }
  });

  $("#support_category_id").change(function() {
    var category_id = $(this).val();
    var is_value = category_id == '' ? true : false
    $("#support_issue_id").prop('disabled', is_value);
    $("#support_affected_user_id").prop('disabled', is_value);
    $("#support_severity_level_id").prop('disabled', 'disabled');
    $.ajax({
      url: '/issues/category_based',
      method: "GET",
      dataType: "json",
      data: { category_id: category_id },
      success: function(data) {
        var issues = data["issues"];
        var items = "<option value=''> -- Please select --</option>";
        $.each(issues, function(i, item) {
          items += "<option value=\"" + item.id + "\">" + item.name + "</option>";
        });
        $("#support_issue_id").html(items);
      }
    });

  });

  $('#support_category_id, #support_affected_user_id, #support_severity_level_id').change(function() {
    var support_category_id = $('#support_category_id').children("option:selected").val();
    var affected_user_id = $("#support_affected_user_id").children("option:selected").val();
    if (support_category_id == 7 || (support_category_id == '' || affected_user_id == '')) {
      if (support_category_id == 7) {
        $("#support_issue_id").val(3).prop('disabled', true);
        $("#support_affected_user_id").val(1).prop('disabled', true);
        $("#support_severity_level_id").val(1).prop('disabled', 'disabled');
      } else {
        $('#support_severity_level_id').val('');
      }
      $("#response_time").text("");
      return;
    }


    var day = '30 min';
    var severity_time = ["0", "30 mins", "60 mins", "24 Hours", "1-2 days", "1-3 days"];
    $("#support_severity_level_id").prop('disabled', 'disabled');
    var severity = $('#support_severity_level_id').val();
    switch (support_category_id) {
      case '1':
        severity = 1
        if (affected_user_id == '4') {
          severity = 2
        } else if (affected_user_id == '5') {
          severity = 4
        }
        break;
      case '2':
        severity = 2
        if (affected_user_id == '4') {
          severity = 3
        } else if (affected_user_id == '5') {
          severity = 4
        }
        break;
      case '3':
        severity = 2
        if (affected_user_id == '3' || affected_user_id == '4') {
          severity = 3
        } else if (affected_user_id == '5') {
          severity = 4
        }
        break;
      case '4':
        severity = 3
        if (affected_user_id == '4' || affected_user_id == '5') {
          severity = 4
        }
        break;
      case '5':
        severity = 4
        break;
      case '6':
        severity = 5
        break;
    }

    $('#support_severity_level_id option')
      .removeAttr('selected')
      .filter("[value=" + severity + "]")
      .prop('selected', true);

    if (severity == 1) {
      $("#response_time").html("Expected response time: " + severity_time[severity] + ", outside business hours.<br>You will be contacted by a support agent within " + severity_time[severity]);
    } else {
      $("#response_time").html("Expected response time: " + severity_time[severity] + ", within business hours.<br>You will be contacted by a support agent within " + severity_time[severity]);
    }
  });


  $('#support_device_other').click(function() {
    if ($(this).is(':checked')) {
      $("#support_other_affected_device").prop('disabled', false);
    } else {
      $("#support_other_affected_device").val("");
      $("#support_other_affected_device").prop('disabled', true);
    }
  });


  $('#support_browser_other').click(function() {
    if ($(this).is(':checked')) {
      $("#support_other_affected_browser").prop('disabled', false);
    } else {
      $("#support_other_affected_browser").val("");
      $("#support_other_affected_browser").prop('disabled', true);
    }
  });

  window.setTimeout(function() {
    $(".alert.alert-dismissible").fadeTo(500, 0).slideUp(500, function() {
      $(this).remove();
    });
    $(".alert.alert-auto-dismiss").alert('close')

  }, 8000);

});

import { DirectUpload } from "@rails/activestorage"
window.addEventListener('DOMContentLoaded', () => {
  const input = document.querySelector('input[type=file]')

  // Bind to file drop - use the ondrop on a parent element or use a
  //  library like Dropzone
  const onDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files;
    Array.from(files).forEach(file => uploadFile(file))
  }
  // Bind to normal file selection

  input.addEventListener('change', (event) => {
    Array.from(input.files).forEach(file => uploadFile(file))
    // you might clear the selected files from the input
    input.value = null
  })

  const uploadFile = (file) => {
    // your form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url

    const url = input.dataset.directUploadUrl

    const upload = new DirectUpload(file, url)
    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        var img = '<img class=\"card-img-top\" src=\"' + window.URL.createObjectURL(file) + '" alt=\"' + file.name + '\" />';

        if (blob.content_type.includes('application')) {
          img = '<div class=\"m-t-10\" >' + file.name + '</div>'
        }
        var construc = '<div class=\"grid-column-child div_' + blob.id + '\"><div class=\"overlay-action\"><a class=\"close-btn\" role=\"button\" data-confirm=\"Are you sure you want to remove this image?\" data-remote=\"true\" rel=\"nofollow\" data-method=\"delete\" href=\"/supports/delete_image_attachment?id=' + blob.signed_id + '\"><i class=\"fa fa-times-circle\"></i></a></div><div class=\"card\">' + img + '</div></div>';
        $('.grid-columns').show().append(construc);

        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = input.name
        document.querySelector('form#cretaeSupport').appendChild(hiddenField)
      }
    })
  }
});