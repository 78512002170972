
function getItems() {
  var items = [];
  $('.media-col').each(function() {
    var $href = $(this).data('image'),
    metadata = $(this).data('metadata')
    $width  = metadata.width,
    $height = metadata.height;
    $title = $(this).data('title');

    var item = {
      src: $href,
      w: $width,
      h: $height,
      title: $title
    }
    items.push(item);
  });
  return items;
}
window.addEventListener('DOMContentLoaded', () => {

  var $pswp = $('.pswp')[0];
  var items = getItems();
  $('.media-col').click(function(e) {
        // define options (if needed)
    var options = {
             // history & focus options are disabled on CodePen
        history: false,
        focus: false,

        showAnimationDuration: 0,
        hideAnimationDuration: 0

    };
      var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
      lightBox.init();
  });
})

