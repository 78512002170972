// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("jquery")
require("bootstrap/dist/js/bootstrap")
//require('datatables.net')
require('datatables.net-bs4')
require("datatables.net-bs4/css/dataTables.bootstrap4.css")
require('jquery-validation')
require("custom/support")
require("custom/gallery")
require('bootstrap-3-typeahead')
import autosize from "autosize";
global.autosize = autosize;


import $ from 'jquery';
global.$ = jQuery;
import 'select2'
import 'select2/dist/css/select2.css'

import '../stylesheets/application';
import '../stylesheets/es6/app'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//      
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)















