import Core from './core/core';
import ThemeConfigurator from './theme-configurator/theme-configurator'

export default class SupportAecor extends Core {

    constructor () {
        super()
        this.initThemeConfig()
    }

    initThemeConfig() {
        ThemeConfigurator.themeConfigurator()
    }
}

$(() => {
   window.SupportAecor = new SupportAecor();
});
